<script>
var moment = require('moment');
moment.locale ('fr');
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Switches from "vue-switches";

import Swal from "sweetalert2";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';
import { Erreur } from '../../../helpers/error';
import {Api} from '../../../helpers'
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Switches,
    PageHeader,
    loaderProcessVue,
  },

  data() {
    return {
      categories:[],
      categorie:[],
      process:false,
      modalOk:false,
      updateModal:false,
      enabled:true,
      video:{
        image:"",
        description:"",
        annee:"",
        duree:"",
        titre:"",
      },
      channelId:"",
      tags:[],
      tagList:[],
      channels:[],
      moment:moment,
      contenu:"video",
      videos:[],
      programmes:[],
      title: "Liste de Vidéos de bannière",
      items: [
        {
          text: "outils",
          // href: "/projects/categories",
        },
        {
          text: "Bannière",
          active: true,
        },
      ],
    };
  },
  methods:{

    // addTags(event){
    //             event.preventDefault()
    //             var val = event.target.value.trim()
    //             if (val.length > 0){
    //               this.tags.push(val)
    //                 event.target.value = ''
                  
    //             }
    //     },

    showOneBanner(){
        this.updateModal = true
    },
    showModal(){
      this.modalOk = true
    },

    onFileSeelcted(event){
      this.selectedFile = event.target.files[0]
    },


    imgVideoSelect(event){
      this.imgSelect = event.target.files[0]
    },


    uploadVideo(){
      this.process = true
      this.modalOk = false

      Api.postFormData(`/streamvod/rest/upload?categories=${this.categorie}&tags=${this.tags}`, {
        file: this.selectedFile,
        image: this.imgSelect,
        channelId: this.channelId,
        description: this.video.description,
        duration: this.video.duree,
        title: this.video.titre,
        years: this.video.annee,
      })
        .then(function () {
          Swal.fire("Success!", "Tag ajoutée", "success");
          location.reload()
          this.process = false
        })
        .catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })

      // let token  = localStorage.getItem("jwtToken");
      // let formData = new FormData();
      // formData.append("file", this.selectedFile)
      // formData.append("image", this.imgSelect)
      // // formData.append("channelId", this.channelId)
      // // formData.append("title", this.video.titre)
      // // formData.append("description", this.video.description)
      // // formData.append("duration", this.video.duree)
      // // formData.append("years", this.video.annee)

      // axios.post("http://localhost:8080/streamvod/rest/upload?categories="+ this.categories +
      // "&channelId="+ this.channelId +"&description="+ this.video.description +
      // "&duration="+ this.video.duree +"&tags="+ this.tags +"&title="+ this.video.titre +
      // "&years="+ this.video.annee,
      // formData,
      // {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: "Bearer " + token,
      //   }
      // })
      // .then(function (response) {
      //   Swal.fire("Succes", "Video enregistrée", "success");
      //   this.process = false
      //   location.reload()
      // }).catch((error) => {
      //     this.process = false
      //     if(error.message == "Request failed with status code 404"){
      //       Swal.fire("Erreur!", "Chemin introuvable!", "error");
      //     }
      //     if(error.message == "Request failed with status code 500"){
      //       Swal.fire("Erreur!", "Veuillez rafraichir la page!", "error");
      //     }
      //     if(error.message == "Request failed with status code 401"){
      //       Swal.fire("Erreur!", "Vous n'etes pas connecté!", "error");
      //     }
      //     if(error.message == "Request failed with status code 400"){
      //       Swal.fire("Erreur!", "Mauvaise requête!", "error");
      //     }
      // })
    },



  },
  mounted(){

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row justify-content-end">
        <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn c2play-primary" style="position: relative; left: 90%;" @click="showModal">
              {{ $t("addButton") }}
            </button>
        </div>
    </div>

    <switches v-model="enabled" type-bold="false" color="success" class="ms-1 mb-0"></switches>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 100px">{{ $t('pages.banner.entete.th1') }}</th>
                  <th scope="col">{{ $t('pages.banner.entete.th2') }}</th>
                  <th scope="col">{{ $t('pages.banner.entete.th3') }}</th>
                  <th scope="col">{{ $t('pages.banner.entete.th4') }}</th>
                  <th scope="col">{{ $t('actions.title') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="video in videos.slice().reverse()" :key="video.id">
                  <td>
                    <img v-if="video.image.url" :src="video.image.url" alt class="avatar-sm" />
                    <img v-if="!video.image.url" src="@/assets/logos/galerie.png" alt class="avatar-sm" />
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14">
                      {{video.title}}
                    </h5>
                  </td>
                  <td>{{ moment(video.created) }}</td>
                  <td>
                    <span>
                    </span
                    >
                  </td>
                  <td>
                    <b-dropdown
                      class="card-drop"
                      variant="white"
                      menu-class="dropdown-menu-end"
                      right
                      toggle-class="p-0"
                    >
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>
                      <b-dropdown-item @click="showOneBanner"
                        >{{ $t('actions.modifier') }}</b-dropdown-item
                      >
                      <b-dropdown-item href="javascript: void(0);"
                        >{{ $t('actions.supprimer') }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


                  <b-modal
                    v-model="modalOk"
                    id="modal-xl"
                    size="xl"
                    :title="$t('pages.videos.formulaire.title')"
                    title-class="font-18"
                    hide-footer
                  >
                   <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

          <div class="card-body">
            <form>
              <div class="form-group row mb-2">
                <div class="col-lg-12">
                  <label for="projectname" class="col-form-label"
                  >{{ $t("pages.videos.formulaire.titre.label") }}</label
                >
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="video.titre"
                    :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
                  />
                </div>
              </div>


              <div class="row mb-2">
                <div class="form-group col-lg-6 ">
                  <label for="projectname" class="col-form-label"
                    >{{ $t('pages.videos.formulaire.files.image') }}</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="imgVideoSelect($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6"> 
                  <label for="projectbudget" class="col-form-label "
                    >{{ $t('pages.videos.formulaire.files.video') }}</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="onFileSeelcted($event)"
                      class="form-control"
                      accept=".MOV, .AVI, .MP4"
                    />
                  </div>
                </div>
              </div>


                  <div class="form-group row mb-2">
                    <label for="projectdesc" class="col-form-label"
                      >{{ $t('pages.videos.formulaire.description.label') }}</label
                    >
                    <div class="col-lg-12">
                      <textarea
                        id="projectdesc"
                        class="form-control"
                        rows="3"
                        v-model="video.description"
                        :placeholder="$t('pages.videos.formulaire.description.placeholder')"
                      ></textarea>
                    </div>
                  </div>

            </form>

            <div class="row"  >
              <div class="col-lg-12" style="margin-top: 10px;">
                <button type="submit" class="btn c2play-primary" @click="uploadVideo" style="position: relative; left: 45%;">
                  {{ $t('addButton') }}
                </button>
              </div>
            </div>

          </div>
                  </b-modal>



                  <b-modal
                    v-model="updateModal"
                    id="modal-xl"
                    size="xl"
                    :title="$t('pages.videos.formulaire.title')"
                    title-class="font-18"
                    hide-footer
                  >
                   <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

          <div class="card-body">
            <form>
              <div class="form-group row mb-2">
                <div class="col-lg-12">
                  <label for="projectname" class="col-form-label"
                  >{{ $t("pages.videos.formulaire.titre.label") }}</label
                >
                  <input
                    id="projectname"
                    name="projectname"
                    type="text"
                    class="form-control"
                    v-model="video.titre"
                    :placeholder="$t('pages.videos.formulaire.titre.placeholder')"
                  />
                </div>
              </div>


              <div class="row mb-2">
                <div class="form-group col-lg-6 ">
                  <label for="projectname" class="col-form-label"
                    >{{ $t('pages.videos.formulaire.files.image') }}</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectname"
                      name="projectname"
                      type="file"
                      @change="imgVideoSelect($event)"
                      class="form-control"
                      placeholder="Entre le lien de la vidéo..."
                      accept=".png, .jpeg, .jpg"
                    />
                  </div>
                </div>

                <div class="form-group col-lg-6">
                  <label for="projectbudget" class="col-form-label "
                    >{{ $t('pages.videos.formulaire.files.video') }}</label
                  >
                  <div class="col-lg-12">
                    <input
                      id="projectbudget"
                      name="projectbudget"
                      type="file"
                      @change="onFileSeelcted($event)"
                      class="form-control"
                      accept=".MOV, .AVI, .MP4"
                    />
                  </div>
                </div>
              </div>


                  <div class="form-group row mb-2">
                    <label for="projectdesc" class="col-form-label"
                      >{{ $t('pages.videos.formulaire.description.label') }}</label
                    >
                    <div class="col-lg-12">
                      <textarea
                        id="projectdesc"
                        class="form-control"
                        rows="3"
                        v-model="video.description"
                        :placeholder="$t('pages.videos.formulaire.description.placeholder')"
                      ></textarea>
                    </div>
                  </div>

            </form>

            <div class="row"  >
              <div class="col-lg-12" style="margin-top: 10px;">
                <button type="submit" class="btn c2play-primary" @click="uploadVideo" style="position: relative; left: 45%;">
                  {{ $t('addButton') }}
                </button>
              </div>
            </div>

          </div>
                  </b-modal>

                  <loaderProcessVue v-if="process == true"></loaderProcessVue>
    <!-- end row -->
  </Layout>
</template>

<style>

</style>
